import request from '@/utils/request'

// 商品列表
export function goodsList(data) {
  return request({
    url: '/goods/list',
    method: 'post',
    data: data
  })
}
// 添加商品/修改
export function goodsUpsert(data) {
  return request({
    url: '/goods/upsert',
    method: 'post',
    data: data
  })
}

// 删除商品
export function goodsDel(id) {
  return request({
    url: '/goods/delete/' + id,
    method: 'delete'
  })
}

// 商品信息
export function goodsInfo(id) {
  return request({
    url: '/goods/info/' + id,
    method: 'get'
  })
}

// UUID导入
export function uuidUpsertImport(data) {
  return request({
    url: '/uuid_warehouse/upsert/import',
    method: 'post',
    data: data
  })
}

// UUID列表
export function uuidList(data) {
  return request({
    url: '/uuid_warehouse/list',
    method: 'post',
    data: data
  })
}

// 删除UUID
export function uuidDel(id) {
  return request({
    url: '/uuid_warehouse/delete/' + id,
    method: 'delete'
  })
}

// 添加修改商品分类
export function goodsTypeUpsert(data) {
  return request({
    url: '/goods_types/upsert',
    method: 'post',
    data: data
  })
}

// 产品分类列表
export function goodsTypeList() {
  return request({
    url: '/goods_types/list',
    method: 'get'
  })
}

// 删除商品分类
export function goodsTypeDel(id) {
  return request({
    url: '/goods_types/delete/' + id,
    method: 'delete'
  })
}

// 商品参数模板添加修改
export function goodsParamTemplateUpsert(data) {
  return request({
    url: '/goods_param_template/upsert',
    method: 'post',
    data: data
  })
}

// 获取参数模板
export function goodsParamTemplateList(data) {
  return request({
    url: '/goods_param_template/list',
    method: 'post',
    data: data
  })
}

export function upsertGoodsParamTemplateType(data) {
  return request({
    url: '/goods_param_type/upsert',
    method: 'post',
    data: data
  })
}

export function delGoodsParamTemplateType(id) {
  return request({
    url: '/goods_param_type/delete/' + id,
    method: 'delete'
  })
}
export function getGoodsParamTemplateType(data) {
  return request({
    url: '/goods_param_type/list',
    method: 'get'
  })
}

// 获取模板详情
export function goodsParamTemplateInfo(id) {
  return request({
    url: '/goods_param_template/info/' + id,
    method: 'get'
  })
}

// 删除商品分类
export function goodsParamTemplateDel(id) {
  return request({
    url: '/goods_param_template/delete/' + id,
    method: 'delete'
  })
}

export function getTemplate(data) {
  return request({
    url: '/goods_param_template/list',
    method: 'post',
    data: data
  })
}

export function upsetTemplate(data) {
  return request({
    url: '/goods_param_template/upsert',
    method: 'post',
    data: data
  })
}

export function delTemplate(data) {
  return request({
    url: '/goods_param_template/delete/' + data,
    method: 'delete'
  })
}

export function getOrderInfo(data) {
  return request({
    url: '/order/info/' + data,
    method: 'get'
  })
}

export function commentUpsert(data) {
  return request({
    url: '/order_comment/upsert',
    method: 'post',
    data: data
  })
}

export function commentList(data) {
  return request({
    url: '/order_comment/list',
    method: 'post',
    data: data
  })
}

export function customerInfo(data) {
  return request({
    url: '/customer/info/' + data,
    method: 'get'
  })
}

export function getFileInfo(data) {
  return request({
    url: '/file/detail',
    method: 'post',
    data: data
  })
}

export function upsertCodetypes(data) {
  return request({
    url: '/code_types/upsert',
    method: 'post',
    data: data
  })
}

export function listCodetypes() {
  return request({
    url: '/code_types/list',
    method: 'get'
  })
}

export function delCodetypes(id) {
  return request({
    url: '/code_types/delete/' + id,
    method: 'delete'
  })
}

export function getArtwordAttch(data) {
  return request({
    url: '/artwork/infos',
    method: 'post',
    data: data
  })
}

export function getPackingAttch(data) {
  return request({
    url: '/packing/infos',
    method: 'post',
    data: data
  })
}

export function getArtwordSingle(id) {
  return request({
    url: '/artwork/info/' + id,
    method: 'get'
  })
}

