<template>
  <v-dialog v-model="dialogProduct" persistent max-width="1400px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-container fluid>
          <!-- Entry Info section -->
          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.pleaseChooseGoods") }}
              </span>
            </v-col>
          </v-row>

          <v-sheet class="mt-5">
            <v-toolbar-items class="d-flex align-center">
              <v-text-field
                class="mx-2"
                :label="$vuetify.lang.t('$vuetify.search')"
                prepend-inner-icon="mdi-magnify"
                hint="Press Enter / Unfocus to Confirm"
                clearable
                @change="(item) => handleSearch(item)"
              />

              <v-text-field
                style="width: 20px"
                class="mx-2"
                label="Go to Page"
                @change="(item) => handleGo2Page(item)"
                hint="Press Enter / Unfocus to Confirm"
                type="number"
                prepend-inner-icon="mdi-magnify"
                :suffix="'/ ' + data_table_options.totalItems"
              />

              <v-spacer />

              <v-select
                :items="goodsType"
                :item-text="(item) => item.text"
                :item-value="(item) => item.value"
                :label="$vuetify.lang.t('$vuetify.chooseType')"
                @change="(item) => handleType(item)"
              />
            </v-toolbar-items>
          </v-sheet>

          <!-- Expandable Table -->
          <v-data-table
            class="elevation-8 mt-4"
            :headers="headers"
            :items="datas"
            v-model="selectList"
            no-data-text="No Data Found"
            :page="data_table_options.page"
            :server-items-length="data_table_options.totalItems"
            :options.sync="data_table_options"
            :footer-props="data_table_options.footerProps"
            :single-select="false"
            mobile-breakpoint="960"
            height="500"
            fixed-header
            show-select
            @pagination="(item) => handlePage(item)"
            @update:items-per-page="handleItemPerPage"
          >
            <template slot="no-data">
              <v-chip color="green" class="ma-4 subtitle-4 font-weight-bold">
                Sorry, nothing to display here
              </v-chip>
            </template>

            <template v-slot:[`item.image`]="{ item }">
              <img
                :src="
                  item.image != ''
                    ? 'https://kherponline.a9kh.com/data/file/' + item.image
                    : blankIMG
                "
                width="50px"
                style="
                  width: 65px;
                  height: 70px;
                  margin: 10px;
                  border-radius: 50px;
                "
              />
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-8 pt-4">
        <v-spacer />

        <v-btn color="red" outlined @click="handleCancel">
          {{ $vuetify.lang.t("$vuetify.cancel") }}
        </v-btn>

        <v-btn color="primary" @click="handleSelectProduct">
          {{ $vuetify.lang.t("$vuetify.confirm") }}
        </v-btn>

        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { goodsList, goodsTypeList } from "@/api/goods";

const newSearch = () => {
  return {
    type_code: "",
    skip: 0,
    limit: 10,
    key: "",
  };
};

function changeNullItems(department) {
  if (department.items == null) {
    department.items = [];
  } else {
    for (let i = 0; i < department.items.length; i++) {
      changeNullItems(department.items[i]);
    }
  }
}

function FilterCategory(mainCategory, categories, list) {
  if (categories != undefined) {
    for (let i = 0; i < categories.length; i++) {
      list.push({
        text: mainCategory + categories[i].name,
        value: categories[i].code,
      });
      if (categories[i].items != null) {
        FilterCategory(
          mainCategory + categories[i].name + " / ",
          categories[i].items,
          list
        );
      }
    }
  }
  return list;
}

const newDataOption = () => {
  return {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    totalItems: 1,
    footerProps: {
      itemsPerPageOptions: [10, 20, 30, 40, 50],
    },
  };
};

export default {
  name: "SelectProduct",
  props: { dialogProduct: Boolean, selected: Array },
  data() {
    return {
      goodsType: [],
      datas: [],
      selectList: [],
      searchForm: newSearch(),
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.productPicture"),
          value: "image",
          width: "20",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsCode"),
          value: "other_code",
          width: "300",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsName"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.type"),
          value: "type_name",
          width: "120",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          width: "180",
          sortable: false,
        },
      ],
      data_table_options: newDataOption(),
    };
  },
  methods: {
    getData() {
      goodsList(this.searchForm)
        .then((data) => {
          if (data.items != null) {
            this.data_table_options.totalItems =
              data.total <= 10
                ? 1
                : Math.round(
                    Math.floor(data.total / 10) + (data.total % 10 == 0 ? 0 : 1)
                  );

            this.datas = [...data.items];
          } else {
            this.datas = [];
            this.data_table_options.totalItems = 1;
            // this.$toast.info("No Data Found");
          }
        })
        .catch((err) => console.log(err));
    },
    getGoodType() {
      goodsTypeList()
        .then((data) => {
          if (data.items != null) {
            const temp = [...data.items];

            temp.forEach((p) => {
              changeNullItems(p);
            });

            this.goodsType = FilterCategory("", temp, []);

            this.goodsType.push({ text: "All", value: -1 });
          } else {
            this.goodsType = [];
          }
        })
        .catch((err) => console.log(err));
    },
    handleSelectProduct() {
      var temp = [];

      this.selectList.forEach((p) => {
        p.nick_name = p.name;
        p.type = 1;

        temp.push(p);
      });

      this.$emit("update:selected", temp);
      this.$emit("update:dialogProduct", false);
    },
    handleCancel() {
      this.$emit("update:dialogProduct", false);
    },
    handleItemPerPage(item) {
      this.searchForm.limit =
        item != -1 ? item : this.data_table_options.totalItem;

      this.getData();
    },
    handleType(item) {
      if (item == -1) {
        this.searchForm.type_code = "";
      } else {
        this.searchForm.type_code = item;
      }

      this.getData();
    },
    handleSearch(item) {
      this.searchForm.key = item;

      this.getData();
    },
    handlePage(item) {
      this.searchForm.skip = item.itemsPerPage * (item.page - 1);

      this.getData();
    },
    handleGo2Page(item) {
      item = Number(item);

      if (item != "" || item != 0) {
        this.data_table_options.page = item;
      } else {
        this.data_table_options.page = 1;
      }
    },
  },
  mounted() {
    this.getData();
    this.getGoodType();
  },
};
</script>